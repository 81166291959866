<template>
  <v-alert type="info" color="primary" border="left" text tile>
    <div :style="{fontSize: '14px'}">
      <slot></slot>
    </div>
  </v-alert>
</template>

<script>
export default {
  props: {
    message: String
  }
}
</script>